import * as React from "react"
import "@fontsource/raleway/500.css"
import styled from 'styled-components'

// styles

const Headline = styled.h4`
  border-bottom: 1px solid #dfcea1;
  padding-bottom: 9px;
  line-height: normal;
  max-width: 900px;
  margin: 20px auto;

  span {
    border-bottom: 5px solid #e2c3a3;
    padding-bottom: 10px;
    color: #b13a04;
    font-size: 16px;
    font-weight: 500;
  }
`;

// markup
const SubHeader = (props) => {
  return (
    <Headline><span>{props.title}</span></Headline>
  )
}

export default SubHeader