import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import Header from '../../components/header'
import SubHeader from '../../components/subheader'
import CharacterGrid from '../../components/charactergrid'
import InBrief from '../../components/inbrief'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageRight, ImageFull, ImageCenter } from '../../components/imageelements'
import { SynopsisAct } from '../../components/synposisstyling'
import Bibliography from '../../components/bibliography.js'
import { Helmet } from "react-helmet"
import AudioSet from '../../components/audioset'
import { AdContainer } from '../../components/adstyling'
import { FunFactsHeader } from '../../components/funfactsstyling'
import { MainBodySection } from '../../components/generalstyling'
import VideoPlayer from '../../components/videoplayer'
import BlockQuote from '../../components/blockquote'

const fairycharacters = [
  {name: 'Oberon', voice: 'Countertenor', desc: 'The king of the fairies. Oberon guides the narrative through his servant Puck. An otherworldly force who can be more than a little creepy.'},
  {name: 'Tytania', voice: 'Soprano', desc: 'The queen of the fairies. A force to be reckoned with though Oberon dominates her. Falls for Bottom as a result of a love potion to hilarious effect.'},
  {name: 'Puck', voice: 'Spoken', desc: 'A mischievous sprite who does the bidding of Oberon (to sometimes unintended effect). Puck acts without thought for others but acts essentially innocently.'},
  {name: 'Cobweb', voice: 'Treble', desc: 'The first of Tytania\'s four fairy servants. Usually sung by a boy treble but occasionally by a female singer.'},
  {name: 'Mustardseed', voice: 'Treble', desc: 'The second of Tytania\'s four fairy servants. Usually sung by a boy treble but occasionally by a female singer.'},
  {name: 'Moth', voice: 'Treble', desc: 'The third of Tytania\'s four fairy servants. Usually sung by a boy treble but occasionally by a female singer.'},
  {name: 'Peaseblossom', voice: 'Treble', desc: 'The fourth of Tytania\'s four fairy servants. Usually sung by a boy treble but occasionally by a female singer.'},
];

const royalcharacters = [
  {name: 'Lysander', voice: 'Tenor', desc: 'Lysander loves Hermia though she is engaged to his friend Demetrius. Flees Athens with Hermia before the opera begins.'},
  {name: 'Demetrius', voice: 'Baritone', desc: 'Demetrius is engaged to Hermia having been previously engaged to Helena. His cruelty to Helena in front of Oberon sets the play\'s events in motion.'},
  {name: 'Hermia', voice: 'Mezzo-soprano', desc: 'Loves Lysander but is betrothed to Demetrius. She and Lysander are fleeing into the forest together at the start of the opera to escape the wrath of her father (who wants her to marry Demetrius).'},
  {name: 'Helena', voice: 'Soprano', desc: 'A friend of Hermia who was previously engaged to Demetrius before he called it off to marry Hermia. She still loves Demetrius despite this...'},
  {name: 'Theseus', voice: 'Bass', desc: 'The Duke of Athens (the chap who slew the minotaur in Greek legend). A small role in Britten\'s adaptation only appearing in Act III.'},
  {name: 'Hippolyta', voice: 'Contralto', desc: 'The queen of the Amazons. Hippolyta is to marry Theseus. A small role in Britten\'s adaptation only appearing in Act III.'},
];

const mechanicalcharacters = [
  {name: 'Bottom', voice: 'Bass Baritone', desc: 'Nick Bottom, the weaver. He provides considerable comic relief, particularly when he gains his donkey\'s head and frolics with Tytania. Plays Pyramus in the play within the play.'},
  {name: 'Quince', voice: 'Bass', desc: 'The leader of the mechanicals troupe though Bottom often rides roughshod over his opinions.'},
  {name: 'Flute', voice: 'Tenor', desc: 'A bellow-mender excited by the prospect of acting in a play. Takes the role of Thisbe, Pyramus\'s female lover.'},
  {name: 'Snug', voice: 'Bass', desc: 'A joiner who is timid and a bit thick. Plays the Lion that (rather sweetly) fails to eat Thisbe.'},
  {name: 'Snout', voice: 'Tenor', desc: 'A tinker who plays the hilarious role of Wall (which he does badly as he\'s so nervous).'},
  {name: 'Starveling', voice: 'Baritone', desc: 'The tailor who plays the part of the moon. He does this with a lantern, and is much mocked for it by the Athenians.'},
];

const inbrief = {name: 'A Midsummer Night\'s Dream', composer: "Benjamin Britten", librettist: "Shakespeare adapted by Britten and Peter Pears", language: "English", date: 'June 11th, 1960', acts: "Three", musiclength: "Two hours, thirty five minutes"};

const bibliography = [
  {name: 'A Midsummer Night\'s Dream', writer: 'William Shakespeare', url: 'https://amzn.to/3gt3pp3'},
  {name: 'The Operas of Benjamin Britten: Expression and Evasion', writer: 'Claire Seymour', url: 'https://amzn.to/2WipAHk'},
  {name: 'A Midsummer Night\'s Dream', writer: 'Overture Opera Guides', url: 'https://amzn.to/2UKOfEa'},
  {name: 'The Music of Benjamin Britten', writer: 'Peter Evans', url: 'https://amzn.to/3yec6tv'},
  {name: 'Benjamin Britten', writer: 'Michael Oliver', url: 'https://amzn.to/3ki4tgF'},
  {name: 'Benjamin Britten: A Life for Music', writer: 'Neil Powell', url: 'https://amzn.to/3879eUF'},
]

const PuckQuote = {content: 'If we shadows have offended,<br>Think but this, and all is mended:<br>That you have but slumbered here<br>While these visions did appear….', quotee: 'Puck, A Midsummer Night\'s Dream'}
const WomanQuote = {content: '‘You are eunuch, Herr Deller?’'}
const DellerQuote = {content: '‘I’m sure you mean unique, madam.’'}

// markup
const DreamPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>A Midsummer Night's Dream | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operas/dream" />
          <meta name="description" content="A guide to Benjamin Britten's enchanting Shakespearean opera, a Midsummer Night's Dream including Synopsis, Music & Arias, Fun Facts, Running Time and much more!" />
        </Helmet>
        <Hero title="A Midsummer Night's Dream" subtitle="An Opera by Benjamin Britten"/>
        <MainBodySection>

          <BlockQuote quote={PuckQuote}/>

          <p><i>A Midsummer Night’s Dream</i> (<i>MSND</i>) is an appealing opera in part because of how flexible it is. On the simplest level, it is an evening of sheer enchantment, a traditional fairytale with plenty of comedy. On another, it is a psychological treasure trove, purity, madness and cruelty crashing against each other to riveting effect. For the music buff, it is also filled with musical homage and satire, from Baroque through Schoenberg, referencing many classic works, particularly in the riotous play within a play of “Pyramus and Thisbe”.</p>

          
          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <p>In adapting Shakespeare’s <i>MSND</i> Britten stuck to the original play text extremely closely. All the characters bar two (Egeus and Philostrate) make the cut, resulting in an opera with a diverse and lively range of characters (nearly 20!). These characters divide into three groups, each with a largely separate plot as in Shakespeare's play. Britten captures these disparate narratives with contrasting sound worlds. From the ethereal beauty of the fairies to the rough and tumble of the mechanicals.</p>

          <AudioSet audiosrc="/audio/butterfly/fairies.mp3" desc="The first appearance of the fairies. Met Opera, 1995"/>

          <AudioSet audiosrc="/audio/butterfly/mechanical_intro.mp3" desc="The mechanicals arrive in the forest. Met Opera, 1995"/>

          <p>Ultimately, it's an evening of pure magic. A show that rewards repeat viewings whilst still managing to be instantly accessible. Genuinely funny, particularly in the final act, and featuring two pairs of young lovers (as well as a brief Donkey/Fairy pairing), it’s a deliciously romantic evening too.</p>

          <ImageFull>
            <StaticImage src="../../images/operas/dream/Fairy_song.jpg" alt="Fairy Song, Arthur Rackham's Illustrations for A Midsummer Night's Dream"/>
            <figcaption>Fairy Song, Arthur Rackham's Illustrations for A Midsummer Night's Dream</figcaption>
          </ImageFull>

          <Header title="In Brief"/>

          <InBrief content={inbrief}/>

          </MainBodySection>

  
          <div id="ezoic-pub-ad-placeholder-103"> </div>


            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Characters"/>

            <p>Britten's <i>MSND</i> has an enormous number of characters by operatic standards, and it is also unusual in that so many of them are prominent. There is no dominant role with all the characters getting their moment, though Oberon, Tytania and Bottom, in particular, have plenty of opportunities to shine. Don't worry if it seems like a lot to remember; one of the joys of this opera is just how enjoyable it is even when you've lost the plot!</p>

            <ImageFull>
              <StaticImage src="../../images/operas/dream/rackham_changeling.jpg" alt="A fairy flew off with the changeling, Arthur Rackham"/>
              <figcaption>A fairy flew off with the changeling, Arthur Rackham</figcaption>
            </ImageFull>

            <SubHeader title="The Fairies"/>

            <CharacterGrid rows={fairycharacters}/>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-104"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <ImageFull>
              <StaticImage src="../../images/operas/dream/hermia_and_helena.jpg" alt="Helena and Hermia, Arthur Rackham"/>
              <figcaption>Helena and Hermia, Arthur Rackham</figcaption>
            </ImageFull>

            <SubHeader title="The Royalty"/>

            <CharacterGrid rows={royalcharacters}/>

            <ImageFull>
              <StaticImage src="../../images/operas/dream/quince_house.jpg" alt="Quince's House, Arthur Rackham"/>
              <figcaption>Quince's House, Arthur Rackham</figcaption>
            </ImageFull>

            <SubHeader title="The Mechanicals"/>

            <CharacterGrid rows={mechanicalcharacters}/>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-105"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Synopsis"/>

            <SynopsisAct>Act I - Running Time: 50 mins</SynopsisAct>

            <p>It is night in the woods outside Athens. Oberon argues with Tytania over a young changeling boy (Oberon wants him for a knight whilst Tytania wants to keep him as his mother was one of her servants). Refusing to give the boy up, Tytania leaves. Oberon calls Puck and sends him to find a magical flower. The juice of this flower has the power to make anyone fall in love with the next person they see. Oberon plans to sprinkle this juice on Tytania’s eyes as she sleeps and then take the boy for himself.</p>
            
            <ImageFull>
              <StaticImage src="../../images/operas/dream/Opening_FelixSanchez_HoustonGrandOpera.jpg" alt="Tytania and Oberon, Houston Grand Opera"/>
              <figcaption>Tytania and Oberon &copy; Felix Sanchez/Houston Grand Opera</figcaption>
            </ImageFull>

            <p>We next meet Lysander, Hermia, Demetrius and Helena. They are variously fleeing/chasing into the woods and form something of a love chain. This unhappy group is overheard by Oberon, who instructs Puck to fix the group by making Demetrius fall for Helena (using the magic flower).</p>

            <ImageFull>
              <StaticImage src="../../images/operas/dream/MSNDQuartet1.jpg" alt="The Quartet at the start of the opera"/>
              <figcaption>The Quartet at the start of the opera</figcaption>
            </ImageFull>

            <p>We now meet our third and final group, the six “mechanicals” or “rustics” (basically working-class men). They are preparing to perform a play for the wedding of Theseus (Duke of Athens) to Hippolyta (Queen of the Amazons). They fuss over the casting, Bottom's shenanigans setting off hilarity, but eventually, all is settled, and they agree to meet later the same night to rehearse.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/dream/Mechanicals_MartySohl_MET.jpg" alt="The Mechanicals in the Metropolitan Opera Production"/>
              <figcaption>The Mechanicals in the Metropolitan Opera Production &copy; Marty Sohl/Metropolitan Opera</figcaption>
            </ImageFull>

            <p>Lost and exhausted Hermia and Lysander fall asleep in the woods. Puck appears, and thinking he has found Demetrius sprinkles the juice on Lysander. Demetrius arrives pursued by Helena but angrily dismisses her. Helena is distraught and seeing Lysander moves to wake him. Surprise, surprise, he awakens and instantly falls in love. Helena runs off thinking Lysander is making fun of her; Lysander chases after, and Hermia awakens to find herself all alone. </p>

            <p>Nearby, Tytania begins to sleep. Oberon appears and places the juice on her eyes.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/dream/MSNDQuartet2.jpg" alt="The Quartet after Puck's mischief"/>
              <figcaption>The Quartet after Puck's mischief</figcaption>
            </ImageFull>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-106"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act II - Running Time: 50 mins</SynopsisAct>

            <p>The mechanicals meet later that same day for a rehearsal. They begin but are soon spotted by Puck, who decides to work some mischief. He transforms Bottom’s head into that of a donkey causing the other mechanicals to flee in fear (much to Bottom’s confusion). Left alone, Bottom sings to himself awakening the nearby Tytania.</p>

            <p>The juice works its magic, and she instantly falls in love with him! In a stunningly funny sequence, she seduces him to bed with some help (and head-scratching) from the fairies.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/dream/bandt_MartySohl_MET.jpg" alt="Tytania leads Bottom away, Metropolitan Opera"/>
              <figcaption>Tytania leads Bottom away &copy; Marty Sohl/Metropolitan Opera</figcaption>
            </ImageFull>

            <p>Oberon is delighted to find his plan for Tytania has worked so well, but his pleasure quickly dissipates when Demetrius enters chasing Hermia. Puck has not completed his task! Demetrius falls asleep (the characters in MSND have a natural penchant for sleeping...), and Oberon drips the juice on his eyes.</p>

            <p>Unfortunately, things get even worse when Helena and Lysander come hurtling in, waking Demetrius, who promptly falls in love with Helena. Hermia enters and Lysander swiftly rejects her, which only enrages Helena more as she thinks the other three are mocking her.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/dream/MSNDQuartet3.jpg" alt="An even greater loving debacle"/>
              <figcaption>An even greater love debacle</figcaption>
            </ImageFull>

            <p>Oberon is now angry with Puck and instructs him to sort the couples out. Puck then leads the four away through the woods, applying the needed juice and antidote to them as they fall asleep.</p>

            <ImageFull>
              <StaticImage src="../../images/operas/dream/puckoberon_AlastairMuir_ENO.jpg" alt="Oberon (Iestyn Davies) beats Puck"/>
              <figcaption>Oberon (Iestyn Davies) beats Puck, &copy; Alastair Muir/English National Opera</figcaption>
            </ImageFull>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-107"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act III - Running Time: 55 mins</SynopsisAct>

            <p>It is nearly dawn. Oberon rouses Tytania, freeing her from the flower’s spell. The four lovers awaken, finally happily paired off. Bottom awakens with his head returned to normal; he thinks it has only been a dream but a bizarre dream at that. The other mechanicals who have been searching for Bottom come across him as he announces that their play is to be performed at court.</p>

            <AudioSet audiosrc="/audio/dream/rose_bottomdream.mp3" desc="Bottom's Dream sung by Matthew Rose."/>

            <ImageCenter>
              <StaticImage src="../../images/operas/dream/bottomdream.jpg" alt="Bottom's Dream"/>
              <figcaption>Bottom's Dream, &copy; Robert Walport/Cuddigan Opera</figcaption>
            </ImageCenter>

            <p>We leave the woods for Athens. It is Theseus and Hippolyta's wedding day. The mechanicals deliver their ludicrous (and hilarious) Pyramus and Thisbe for the three couples.
            </p>

            <p> As the humans head to sleep... the fairies bid us goodbye.</p>

            <VideoPlayer src="https://www.youtube.com/embed/BEgafzaxS98?start=17&autoplay=1" id="BEgafzaxS98" title="Finale, Central City Opera"/>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-108"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="History"/>

            <p>Operas aren’t things that generally get written overnight. It took Britten several years in most cases. However, <i>MSND</i> was something of a rush job, going from conception to opening night in well under a year. The Jubilee Hall in Aldeburgh was undergoing a complete refurbishment, and to celebrate its opening and the 1960 Aldeburgh Festival (Britten’s own festival), a new opera was required. For various reasons, this wasn’t settled upon until late summer 1959, leaving Britten an extremely tight schedule.</p>

            <p>It was partly for practical reasons that Britten and Pears settled upon Shakespeare’s play. “There was no time to get a libretto written, so we took one that was ready to hand. I have always loved A Midsummer Night’s Dream.” However, the practical dimension of the decision should not be overstated. Britten didn’t do anything without reason. There are many themes in the play that fit with Britten’s ethos and work. Since writing "On This Island" with W. H. Auden, Britten had regularly returned to the subject of dreams and darkness whilst innocence was a subject that preoccupied him throughout his life.</p>

            <ImageRight>
              <StaticImage src="../../images/operas/dream/Jubilee_Hall-small.jpg" alt="Jubilee Hall in Aldeburgh"/>
              <figcaption>Jubilee Hall in Aldeburgh</figcaption>
            </ImageRight>

            <p>With the libretto swiftly assembled, Britten set about writing the score. He did so steadily, though he suffered bouts of depression over the winter. His health was uneven; gout, tendonitis and bursitis all plaguing him over the year. Nor was he helped by Imogen Holst, his music assistant, being rushed to hospital with suspected appendicitis and told to take six weeks to rest after the resulting operation.</p>

            <p>The music came together in time. Still, the opening was then threatened by Alfred Deller, the first Oberon (see his fun fact for more), fearing he was inadequately prepared for the acting in an opera little helped by John Cranko’s apparently poor direction (He was a ballet choreographer with limited opera directing experience).</p>

            <p>Everything came together to a near-total success. Frank Howes of the Times of London described it as "a major opera of the size and quality to follow Peter Grimes around the world", and it did indeed soon find its way around the world. The Royal Opera would produce it the year after its premiere, and <i>MSND</i> was rapidly taken up abroad (not infrequently in translation), becoming a part of the international standard repertory.</p>

          </MainBodySection>

            <div id="ezoic-pub-ad-placeholder-109"> </div>
  
            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Fun Facts"/>

            <FunFactsHeader>Oberon</FunFactsHeader>

            <p>Today, countertenors have more or less joined the lineup alongside Tenors, Baritones and Basses, but this is a very recent phenomenon. The steady rise of this vocal type was predominantly in Renaissance and Baroque music until Britten wrote Oberon for Alfred Deller, the leading countertenor of the mid 20th Century. A startling innovation for 20th Century opera, leading men were generally tenors, and Britten would go on to write one further significant countertenor role, The voice of Apollo in <i>Death in Venice</i>.</p>

            <p>An hilarious anecdote relating to the then novelty of Deller’s voice comes from the late Robert Tear: a German woman asked Deller,</p>
            
            <BlockQuote quote={WomanQuote}/>

            <p>To which Deller replied,</p>

            <BlockQuote quote={DellerQuote}/>

            <FunFactsHeader>MSND in Music</FunFactsHeader>

            <p>Britten was far from the only composer to have adapted Shakespeare’s MSND. Henry Purcell produced <i>The Fairy Queen</i>, a series of masques intertwined with a bowdlerised MSND text. Felix Mendelssohn wrote a concert overture and incidental music for the play. This Mendelssohn music contains possibly one of the most recognisable tunes ever written: the wedding March.</p>

            <AudioSet audiosrc="/audio/dream/weddingmarch.mp3" desc="The Wedding March"/>

          </MainBodySection>

            <div id="ezoic-pub-ad-placeholder-110"> </div>
  
            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>            

            <FunFactsHeader>Cuts</FunFactsHeader>

            <p>Britten and Pears adapted their libretto from Shakespeare’s text by editing heavily, but they added only one line, “compelling thee to marry with Demetrius”, which quickly clarifies the plot in Act I. Britten described the process, “to get it into manageable shape, which basically entailed simplifying and cutting an extremely complex story…I do not feel in the least guilty at having cut the play in half. The original Shakespeare will survive.”</p>

            <FunFactsHeader>Magic Flowers</FunFactsHeader>

            <ImageCenter>
              <StaticImage src="../../images/operas/dream/pansy.jpg" alt="The Viola tricolor"/>
              <figcaption>The Viola tricolor</figcaption>
            </ImageCenter>

            <p>Much of the drama in MSND hinges on a magical, love inducing flower. This flower is called “Love-in-idleness” (though the name is not mentioned in Britten’s reduced text) and is said to have been a white flower struck by cupid’s arrow, causing it to turn purple and produce a love potion. “Love-in-idleness” is one of many names for a real flower, the Viola Tricolor, a sort of pansy. It is mentioned in several of Shakespeare’s plays, including The Taming of the Shrew, and is probably also the pansy that Ophelia refers to in Hamlet, “There's pansies, that's for thoughts”.</p>

            <FunFactsHeader>The play within a play</FunFactsHeader>

            <p>While Oberon takes music that seems to harken to Purcell and the forest music has a twelve-tone undercurrent, it is the play within a play that sees Britten at his most wittily reverential. Pyramus first enters to a tune, not unlike the “Miserere” from Verdi's <i>Il Trovatore</i>. The Lion roars to a polka, and Thisbe kills herself to a Victorian ballad. To top it off, Thisbe’s mad aria is a brilliant parody of Lucia di Lammermoor.</p>

            <Header title="Bibliography"/>

            <Bibliography content={bibliography}/>

        </MainBodySection>
    </Layout>
  )
}

export default DreamPage
